import { AccountMenu, Logo, MainMenu } from '../../components'
import { Box, Col, Container, Row } from '@qonsoll/react-design'
import { addProtocolToUrl, createFullUserName, getCSSVariable } from 'helpers'

import { AuthModal } from 'components'
import { Button } from '@qonsoll/react-design'
import { LanguageMenu } from 'domains/Translation/components'
import PropTypes from 'prop-types'
import { StyledHeader } from './LayoutHeader.styles'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const LayoutHeader = ({ isAuthenticated }) => {
  const { app, menu } = useNewspaperConfig()
  const { user } = useUser()
  const { t } = useTranslations()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const displayName = createFullUserName(user?.firstName, user?.lastName)

  const newspaperFrontpageAddress = app?.newspaperFrontpageAddress
  const checkedForProtocolLink = addProtocolToUrl(newspaperFrontpageAddress)

  const handleLogoClick = () => {
    if (!newspaperFrontpageAddress) return

    if (checkedForProtocolLink) {
      window.open(checkedForProtocolLink, '_blank')
    }
  }

  const loginButtonColor = getCSSVariable(
    '--ql-login-button-color',
    'var(--ql-color-white)'
  )
  const loginButtonBgColor = getCSSVariable(
    '--ql-login-button-bg-color',
    'var(--ql-color-accent1)'
  )

  return (
    <>
      <StyledHeader px={3} isAuthenticated={isAuthenticated}>
        <Container bounded>
          <Row v="center" noGutters px={[3, 0]}>
            <Col cw="auto" mr="auto">
              <Logo
                logoUrl={app?.logoUrl}
                onClick={handleLogoClick}
                style={{ cursor: newspaperFrontpageAddress ? 'pointer' : null }}
              />
            </Col>
            {isAuthenticated ? (
              <Col cw="auto" className="accountWrapper">
                <Box display="flex" alignItems="center">
                  <LanguageMenu color={'var(--ql-header-text-color)'} />
                  <AccountMenu
                    id={user?._id}
                    avatar={user?.avatarUrl}
                    displayName={displayName}
                    email={user?.email}
                  />
                </Box>
              </Col>
            ) : (
              <Col cw="auto">
                <Box display="flex" alignItems="center">
                  <LanguageMenu color={`var(--ql-header-text-color)`} />
                  <Button
                    ml={2}
                    color={loginButtonColor}
                    backgroundColor={loginButtonBgColor}
                    onClick={() => setIsModalVisible(true)}>
                    {t('Login')}
                  </Button>
                </Box>
              </Col>
            )}
          </Row>
        </Container>
      </StyledHeader>
      <MainMenu
        menu={menu}
        isAuthenticated={isAuthenticated}
        pointsGiven={user?.pointsGiven}
      />
      <AuthModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  )
}

LayoutHeader.propTypes = {
  isAuthenticated: PropTypes.bool
}

export default LayoutHeader
