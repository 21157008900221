import * as reactDesign from '@qonsoll/react-design'

import ForgotPassword from 'pages/Auth/ForgotPassword'
import Login from 'pages/Auth/Login'
import LoginWithEmail from 'pages/Auth/LoginWithEmail'
import { Modal } from 'antd'
import NewsTipConfirmGuestInfoSimpleForm from 'domains/NewsTip/components/NewsTipConfirmGuestInfoSimpleForm'
import PropTypes from 'prop-types'
import SignUpWithEmail from 'pages/Auth/SignUpWithEmail'
import { styles } from './AuthModal.styles'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const AuthModal = (props) => {
  const { isModalVisible, setIsModalVisible } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  const [isGuestModeConfirmed, setIsGuestModeConfirmed] = useState(false)
  const [isLoginWithEmailFormVisible, setIsLoginWithEmailFormVisible] =
    useState(false)
  const [isForgotPasswordFormVisible, setIsForgotPasswordFormVisible] =
    useState(false)
  const [isSignUpFormVisible, setIsSignUpFormVisible] = useState(false)

  const showLoginWithEmailForm = () => {
    setIsLoginWithEmailFormVisible(true)
    setIsForgotPasswordFormVisible(false)
    setIsSignUpFormVisible(false)
  }
  const showForgotPasswordForm = () => {
    setIsForgotPasswordFormVisible(true)
    setIsLoginWithEmailFormVisible(false)
    setIsSignUpFormVisible(false)
  }
  const showSignUpForm = () => {
    setIsSignUpFormVisible(true)
    setIsForgotPasswordFormVisible(false)
    setIsLoginWithEmailFormVisible(false)
  }
  const handleLoginFormBack = () => {
    setIsForgotPasswordFormVisible(false)
    setIsLoginWithEmailFormVisible(false)
    setIsSignUpFormVisible(false)
  }
  const handleGuestModeCancellation = () => {
    setIsGuestModeConfirmed(false)
  }
  const handleForgotPasswordFormBack = () => {
    showLoginWithEmailForm()
  }
  const handleSignUpFormBack = () => {
    handleLoginFormBack()
  }
  const showPhoneConfirmationForm = () => {
    setIsGuestModeConfirmed(true)
  }

  const headingProps = {
    title: t('Confirm submission'),
    subTitle: t(
      'Please, enter your phone number so we can identify you in the future and contact if necessary'
    ),
    marginBottom: 40
  }

  return (
    <Modal
      centered
      footer={null}
      visible={isModalVisible}
      okText={t('Confirm')}
      cancelText={t('Cancel')}
      title={null}
      closable={true}
      onCancel={() => setIsModalVisible(false)}
      bodyStyle={styles.modalBodyStyles}>
      {isGuestModeConfirmed ? (
        <reactDesign.PageWrapper
          onBack={handleGuestModeCancellation}
          backBtnProps={styles.backBtnProps}
          divided={false}
          alignMiddle
          height="100%"
          headingProps={headingProps}
          contentWidth={['100%', '100%', 400]}>
          <NewsTipConfirmGuestInfoSimpleForm />
        </reactDesign.PageWrapper>
      ) : (
        <>
          {isLoginWithEmailFormVisible ? (
            <LoginWithEmail
              isGuestMode
              guestModeActions={{
                showForgotPasswordForm,
                handleLoginFormBack
              }}
            />
          ) : null}
          {isForgotPasswordFormVisible ? (
            <ForgotPassword
              isGuestMode
              guestModeActions={{
                handleForgotPasswordFormBack
              }}
            />
          ) : null}
          {isSignUpFormVisible ? (
            <SignUpWithEmail
              isGuestMode
              guestModeActions={{
                handleSignUpFormBack
              }}
            />
          ) : null}

          {isLoginWithEmailFormVisible || isForgotPasswordFormVisible ? null : (
            <Login
              isGuestMode
              guestModeActions={{
                showPhoneConfirmationForm,
                showLoginWithEmailForm,
                showSignUpForm
              }}
            />
          )}
        </>
      )}
    </Modal>
  )
}

AuthModal.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func
}

export default AuthModal
