import { useCallback, useEffect } from 'react'

import { checkIsObject } from 'helpers'
import { useQueryParams } from 'hooks'

const useGetNewspaperConfigInitialData = (newspaperConfig, isDev) => {
  const domainNameParams = useQueryParams('newspaperDomainName')

  // The function returns a string in the format of a CSS variable.
  const createCSSVariable = useCallback(
    (name, value) => (name && value ? `${name}: ${value};` : null),
    []
  )

  // The function return newspaper domain name.
  const getDomainName = useCallback(() => {
    try {
      const computedDomainName = isDev
        ? domainNameParams
        : window.location.hostname

      const newspaperDomainName =
        computedDomainName ||
        JSON.parse(localStorage.getItem('newspaperDomainName'))

      if (typeof newspaperDomainName !== 'string') throw new Error()
      return newspaperDomainName
    } catch (error) {
      return null
    }
  }, [])

  //
  useEffect(() => {
    if (!newspaperConfig) return
    const header = newspaperConfig?.header ?? {}
    const publicWizardConfiguration =
      newspaperConfig?.publicWizardConfiguration ?? {}
    const app = newspaperConfig?.app ?? {}
    const footer = newspaperConfig?.footer ?? {}
    const menu = newspaperConfig?.menu ?? {}

    // Header
    const {
      backgroundColor: backgroundColorHeader,
      textColor: headerTextColor,
      avatarBackgroundColor,
      avatarTextColor
    } = header

    // Wizard
    const { loginButtonColor, loginButtonBackgroundColor } =
      publicWizardConfiguration

    // Body
    const { backgroundColor: backgroundColorBody } = app

    // Footer
    const {
      backgroundColor: backgroundColorFooter,
      textColor: footerTextColor
    } = footer

    // Menu
    const {
      backgroundColor: menuBackgroundColor,
      textColor: menuTextColor,
      textColorActive: menuTextColorActive
    } = menu

    // Creating/updating all necessary variables for changing page styles
    const variablesConfig = [
      ['--ql-header-bg', backgroundColorHeader],
      ['--ql-header-avatar-bg', avatarBackgroundColor],
      ['--ql-header-avatar-text-color', avatarTextColor],
      [`--ql-header-text-color`, headerTextColor],
      [`--ql-login-button-color`, loginButtonColor],
      [`--ql-login-button-bg-color`, loginButtonBackgroundColor],
      ['--ql-body-bg', backgroundColorBody],
      ['--ql-footer-bg', backgroundColorFooter],
      [`--ql-footer-text-color`, footerTextColor],
      [`--ql-menu-bg`, menuBackgroundColor],
      [`--ql-menu-text-color`, menuTextColor],
      [`--ql-menu-text-color-active`, menuTextColorActive],
      ['--ql-menu-horizontal-main-item-color-hover', menuTextColorActive]
    ]

    const cssVariables = variablesConfig
      ?.map(([name, value]) => createCSSVariable(name, value))
      ?.filter(Boolean)
      ?.join(' ')

    document.documentElement.style.cssText += cssVariables
    /* --- */

    // Updating local storage to support up-to-date data
    if (newspaperConfig?.domainName) {
      // Updating styles
      localStorage.setItem(
        `${newspaperConfig.domainName}_styles`,
        JSON.stringify(Object.fromEntries(variablesConfig))
      )

      // Updating logo url
      const logoUrl = newspaperConfig?.app?.logoUrl

      logoUrl &&
        localStorage.setItem(
          `${newspaperConfig.domainName}_logo`,
          JSON.stringify(logoUrl)
        )
    }
  }, [newspaperConfig])

  useEffect(() => {
    // Get the domain name of the newspaper
    const newspaperDomainName = getDomainName()

    // If the domain name is not available, exit the useEffect
    if (!newspaperDomainName) return

    // Retrieve stored styles for the newspaper from local storage
    const newspaperStyles = JSON.parse(
      localStorage.getItem(`${newspaperDomainName}_styles`)
    )

    // If styles are found, parse and apply them to the document's root element
    if (checkIsObject(newspaperStyles)) {
      document.documentElement.style.cssText += Object.entries(newspaperStyles)
        ?.map(([name, value]) => `${name}: ${value}`)
        ?.join(';')
    }

    // Retrieve logo url from local storage
    const newspaperLogoUrl = JSON.parse(
      localStorage.getItem(`${newspaperDomainName}_logo`)
    )

    // If logo url are found, loading the image before rendering the component
    if (typeof newspaperLogoUrl === 'string') {
      const image = new Image()
      image.src = newspaperLogoUrl
    }
  }, [])
}

export default useGetNewspaperConfigInitialData
