const styles = {
  modalBodyStyles: {
    borderRadius: '20px',
    height: '80vh',
    width: '100%',
    margin: 0,
    overflow: 'hidden',
    padding: 0
  },
  backBtnProps: { margin: '24px 16px 0' }
}
export { styles }
