import { AuthModal, LoadingOverlay } from 'components'
import { Box, Button, Col, Container, Row, Text } from '@qonsoll/react-design'
import { COLLECTIONS, NEWS_TIP_REQUIRED_FIELDS } from '__constants__'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { STEP_NAMES } from 'domains/NewsTip/components/NewsTipWizard/constants'
import { StyledTextWrapper } from 'components/BoilerplateLayout/components/Footer/Footer.styles'
import { getId } from 'services/api/firebase'
import { message } from 'antd'
import { styles } from './NewsTipWizard.styles'
import { useActionsNewsTipAdvancedForm } from '../NewsTipAdvancedForm/hooks'
import { useGetImageSuggestions } from 'domains/Tags/hooks'
import { useKeyPress } from '@umijs/hooks'
import { useNewsTip } from 'domains/NewsTip/context'
import { useNewsTipWizardSteps } from 'domains/NewsTip/hooks'
import { useNewspaperConfig } from 'contexts'
import { useTranslations } from 'contexts/Translation'

const NewsTipWizard = (props) => {
  const { isGuestMode = false } = props

  const location = useLocation()

  /* context for the tips (primarily is being used for the draft tipps) */
  const { setDraftTipData } = useNewsTip()

  /* State for current step in wizard */
  const [step, setStep] = useState(0)
  /* State for saving wizard data */
  const [value, setValue] = useState({ isGuestMode, isCreatedFromWeb: true })
  const [uploadLoading, setUploadLoading] = useState(false)

  const { AUTHENTICATED } = PATHS

  useEffect(() => {
    !value?._id && setValue({ ...value, _id: getId(COLLECTIONS.NEWS_TIPS) })
  }, [value])

  const [, suggestionsLoading] = useGetImageSuggestions({ value, setValue })

  const steps = useNewsTipWizardSteps({
    setStep,
    uploadLoading,
    setUploadLoading,
    suggestionsLoading,
    value,
    setValue,
    isGuestMode
  })

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance from useHistory hook for redirecting */
  const history = useHistory()
  /* Getting saveForm action */
  const { saveForm } = useActionsNewsTipAdvancedForm()
  const { publicWizardConfiguration } = useNewspaperConfig()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isCaptchaBeingProcessed, setIsCaptchaBeingProcessed] = useState(false)

  /* On finish callback with saving data */
  const onFinish = useCallback(() => {
    setIsCaptchaBeingProcessed(true)

    saveForm(
      { newsTip: value, isGuestMode },
      (preparedDataToSave, errorText) => {
        if (errorText) {
          setIsCaptchaBeingProcessed(false)
          message.error(errorText, 4)
          // handle error here
        } else {
          setIsCaptchaBeingProcessed(false)

          if (isGuestMode) {
            setIsModalVisible(true) // opening of modal

            setDraftTipData(preparedDataToSave) // pushing draft tip data to the context
          } else {
            // redirect ot the info screen
            history.push(AUTHENTICATED.NEWS_TIP_SUCCESSFULLY_CREATED)
          }
        }
      }
    )
  }, [history, saveForm, value])

  /* Getting current step */
  const currentStep = useMemo(() => steps[step], [step, steps])
  const isRequiredField = NEWS_TIP_REQUIRED_FIELDS.find(
    (item) => item === currentStep?.name
  )
  const isMedia = useMemo(
    () => currentStep?.name === STEP_NAMES.MEDIA,
    [currentStep?.name]
  )
  const haveUnloadedData = useMemo(
    () => isMedia && value?.media?.files?.filter((item) => !item?.url)?.length,
    [value?.media, isMedia]
  )
  const isCurrentStepLast = step === steps.length - 1
  const loadingTextFirstLineComputed =
    t('Verifying your access for security purposes') +
    '. ' +
    t('Please wait a moment while we ensure your session is secure') +
    '.'
  const loadingTextSecondLineComputed = t('Thank you for your patience') + '.'

  const onNextButtonClick = useCallback(() => {
    if (isMedia && haveUnloadedData) {
      const element = document.getElementById('uploadMedia')
      element.click()
      return
    }

    return isCurrentStepLast ? onFinish() : onNextStep()
  }, [
    onFinish,
    haveUnloadedData,
    isMedia,
    step,
    steps?.length,
    isCurrentStepLast
  ])

  /* Used to show or hide back button */
  const isBackButtonVisible = useMemo(() => step !== 0, [step])
  /* Getting text for next button based on current step */
  const nextButtonText = useMemo(
    () => t(isCurrentStepLast ? 'Finish' : 'Next step'),
    [step, steps.length, t]
  )

  /* Getting current step component */
  const currentStepComponent = useMemo(
    () => steps?.[step]?.component,
    [step, steps]
  )
  /* Getting current step value */
  const currentStepValue = useMemo(
    () => value[currentStep?.name],
    [currentStep, value]
  )

  const exceptionForFirstWizardStep = step === 0 && isGuestMode

  const computedPublicWizardText = publicWizardConfiguration?.publicWizardText
  const isTitleStep = currentStep?.name === STEP_NAMES.TITLE
  const isPublicWizard =
    location?.pathname !== PATHS.AUTHENTICATED.NEWS_TIP_WIZARD

  /* Setting previous step */
  const onPrevStep = () => {
    if (exceptionForFirstWizardStep) {
      history.push(PATHS.UNAUTHENTICATED.LOGIN) // getting back instead of the wizard change
    } else setStep((prevStep) => prevStep - 1)
  }
  /* Setting next step */
  const onNextStep = () => setStep((prevStep) => prevStep + 1)

  /* Handling enter key press */
  useKeyPress('enter', () => !!currentStepValue && onNextButtonClick())
  /* Handling backspace key press */
  useKeyPress('esc', () => isBackButtonVisible && onPrevStep())

  const buttonPrev = (
    <Box {...styles.summaryButtonWrapperStyles}>
      <Button onClick={onPrevStep} disabled={!!uploadLoading}>
        {t(exceptionForFirstWizardStep ? 'Back to login' : 'Previous step')}
      </Button>
    </Box>
  )

  const buttonNext = (
    <Button
      disabled={isRequiredField ? !currentStepValue : false}
      loading={!!uploadLoading}
      type="primary"
      id="nextButtonClick"
      onClick={onNextButtonClick}>
      {nextButtonText}
    </Button>
  )

  return (
    <>
      <Row h="center" noGutters>
        {currentStep?.name !== STEP_NAMES.SUMMARY && (
          <Col cw={[12, 12, 10, 8, 7]}>
            <Container
              display="flex"
              height="70vh"
              flexDirection="column"
              justifyContent="center">
              <Row noGutters>
                <Col cw={12}>{currentStepComponent}</Col>
              </Row>
              <Row h="right" noOuterGutters>
                <Col cw="auto">{isBackButtonVisible && buttonPrev}</Col>
                <Col cw="auto">{buttonNext}</Col>
              </Row>
              {isTitleStep && isPublicWizard ? (
                <Row noGutters>
                  <Col mt="16px" cw={12}>
                    <StyledTextWrapper
                      dangerouslySetInnerHTML={{
                        __html: computedPublicWizardText
                      }}
                    />
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Col>
        )}
        {currentStep?.name === STEP_NAMES.SUMMARY && (
          <Col cw={12} mr={3}>
            <Box position="relative">
              <Box>{currentStepComponent}</Box>
              <Box {...styles.buttonBlockStyles}>
                {isBackButtonVisible && (
                  <Box {...styles.summaryButtonWrapperStyles}>{buttonPrev}</Box>
                )}
                <Box {...styles.summaryButtonWrapperStyles} ml={3}>
                  {buttonNext}
                </Box>
              </Box>
            </Box>
          </Col>
        )}
      </Row>

      <AuthModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />

      {isCaptchaBeingProcessed ? (
        <LoadingOverlay
          loadingTextComponent={
            <>
              <Row mt={2} h="center">
                <Col cw="auto">
                  <Text color="var(--ql-color-white)" fontSize={['20px']}>
                    {loadingTextFirstLineComputed}
                  </Text>
                </Col>
              </Row>
              <Row mt={3} h="center">
                <Col cw="auto">
                  <Text color="var(--ql-color-white)" fontSize={['28px']}>
                    {loadingTextSecondLineComputed}
                  </Text>
                </Col>
              </Row>
            </>
          }
        />
      ) : null}
    </>
  )
}

NewsTipWizard.propTypes = {
  isGuestMode: PropTypes.bool
}

export default NewsTipWizard
