/**
 * Retrieves the value of a CSS variable from the root element.
 *
 * @param {string} variableName - The name of the CSS variable to retrieve (e.g., '--my-variable').
 * @param {string} fallback - The fallback value to return if the CSS variable is not found or is empty.
 * @returns {string} The value of the CSS variable, or the fallback value if the variable is not found.
 */
const getCSSVariable = (variableName, fallback) => {
  const value = getComputedStyle(document.documentElement)
    .getPropertyValue(variableName)
    .trim()
  return value || fallback
}

export default getCSSVariable
